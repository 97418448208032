<template>
  <div class="header bg_color __flex __rsbc">
    <div class="logo-box">
<!--      <img src="../../../assets/image/common/logo.png" alt="logo" style="width: 53px;height:17px ">-->
      <h2 class="company-name __f16">{{  companyName }}</h2>
    </div>


    <div class="link-box __flex color_white">
      <el-dropdown class="handbook">
        <span class="el-dropdown-link color_white"><i class="el-icon-question"></i></span>
        <el-dropdown-menu slot="dropdown">
<!--          <el-dropdown-item @click.native="toUpdate">功能更新</el-dropdown-item>-->
          <el-dropdown-item @click.native="toBook">操作手册</el-dropdown-item>
          <el-dropdown-item @click.native="toIssue">常见问题</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <router-link to='/sys/message' class="__f22 message-menu "><i class="el-icon-message-solid"></i></router-link>
      <router-link to='/downloadCenter' class="download-menu">下载中心</router-link>

      <el-dropdown class="login-out">
        <div class="el-dropdown-link color_white">
          <el-avatar class="avatar" size="small" :src="(userInfo.user && userInfo.user.avatar) || circleUrl"></el-avatar>
          <span>{{ (userInfo.user && userInfo.user.nickName) || "无名大侠"}}</span>
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
        <el-dropdown-menu slot="dropdown" >
          <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { logoutApi} from "@/api/account"
import { mapState } from "vuex"
export default {
  data () {
    return {
      circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      companyName:localStorage.getItem('companyName'),
    }
  },

  computed:{
    ...mapState({
      userInfo:state=>state.userInfo.userInfo
    })
  },

  methods: {
    // toUpdate() {
    //   let routeData = this.$router.resolve({ path: '/helpCenter/updateRecord' });
    //   window.open(routeData.href, '_blank');
    // },
    toBook() {
      let routeData = this.$router.resolve({ path: '/helpCenter/runBook' });
      window.open(routeData.href, '_blank');
    },
    toIssue() {
      let routeData = this.$router.resolve({ path: '/helpCenter/issue' });
      window.open(routeData.href, '_blank');
    },


    async logout() {
      let res = await logoutApi();
      if (res.code == 200) {
        // localStorage.removeItem('authorization-token')
        // localStorage.removeItem('userInfo')
        localStorage.clear()
        this.$router.push({ path: '/login' })
      } else {
        this.$message.error(res.msg)
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.header{
  padding: 0 30px 0 20px;
  color: rgba(0,0,0,.85);
  line-height: 60px;
  .logo-box{
    width: 200px;
    color: #fff;
    height: 100%;
    img{
      width: 53px;
      height: 17px;
      background-size: cover;
    }
    .company-name{
      display: inline-block;
      margin-left: 5px;
    }
  }
  .link-box{
    .handbook {
      font-size: 26px;
      padding: 0 20px;
      .el-dropdown-link{
        position: relative;
        &:before{
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color:#ff4d4f;
          border:1px solid #fff;
          position: absolute;
          right: -3px;
          top: -3px;
        }
      }
    }
    .message-menu{
      display: inline-block;
      cursor:pointer;
    }
    .download-menu{
      display: inline-block;
      margin: 0 40px;
      cursor:pointer;
    }
    .login-out{
      cursor:pointer;
      .avatar{
        display: inline-block;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }
}
</style>