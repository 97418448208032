import Vue from 'vue'
import VueRouter from 'vue-router'
import  store from '@/store'
import { getUserRoutersApi } from "@/api/account"
import { checkPermissions } from "@/utils/utils"

Vue.use(VueRouter)

/* Layout */
import Layout from '../views/layout/layout'

const routes = [
  {path: '/login', name: 'login',component: () => import( '../views/account/Login.vue')},
  {path: '/forgetPassword', name: 'forgetPassword', component: () => import( '../views/account/ForgetPassword.vue')},
  {path: '/register', name: 'register', component: () => import( '../views/account/Register.vue')},
  {path: '*', component: () => import('@/views/features/404'), hidden: true},
  {path: '/401', component: () => import('@/views/features/401'), hidden: true},
  /*{
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: (resolve) => require(['@/views/features/redirect'], resolve)
      }
    ]
  },*/

  {
    path: '/',
    name: 'layout',
    component: Layout,
    redirect:"/home",
    meta: {title: '首页', icon: 'el-icon-s-home'},
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import( '../views/home/index.vue'),
      },
      {
        path: '/downloadCenter',
        name: 'downloadCenter',
        component: () => import( '../views/downloadCenter/index.vue'),
      },
      {
        path: '/docCenter',
        name: 'docCenter',
        component: () => import( '../views/docCenter/index.vue'),
      },
    ]
  },
  {
    path: '/dms',
    component: Layout,
    redirect: '/dms/statistics',
    name: 'dms',
    meta: {title: '验证对象管理', icon: 'el-icon-s-custom'},
    children: [{
      path: '/dms/statistics',
      name: 'statistics',
      component: () => import('@/views/dms/statistics/index'),
      meta: {title: '设备设施验证汇总', icon: 'product-list',}
    },
    {
      path: '/dms/list',
      name: 'list',
      component: () => import('@/views/dms/list/index'),
      meta: {title: '验证对象列表', icon: ''},
    },
    {
      path: '/dms/list/detail/:objectId/:typeId',
      name: 'detail',
      component: () => import( '@/views/dms/list/detail'),
      meta: {title: '验证对象列表-项目详情', icon: ''},
    },
    {
      path: '/dms/list/history/:id',
      name: 'history',
      component: () => import( '@/views/dms/list/history'),
      meta: {title: '验证对象列表-历史验证', icon: ''},
    },
    ]
  },
  {
    path: '/vms',
    component: Layout,
    redirect: '/vms/veriProject',
    name: 'vms',
    meta: {title: '验证管理', icon: 'el-icon-s-management'},
    children: [{
      path: '/vms/project',
      name: 'project',
      component: () => import('@/views/vms/project/index'),
      meta: {title: '验证项目', icon: ''},
    },
    {
      path: '/vms/project/detail/:id/:typeId/:objectName/:objectId?',  //id为projectId,typeId为类型,objectName为设施名称.objectId为设施id
      name: 'detail',
      component: () => import( '@/views/vms/project/detail'),
      meta: {title: '验证项目-项目详情', icon: ''}
    },
    {
      path: '/vms/project/report/:id?',
      name: 'report',
      component: () => import( '@/views/vms/project/report'),
      meta: {title: '验证项目-报告列表', icon: ''}
    },
    {
      path: '/vms/device/veriHost',
      name: 'veriHost',
      component: () => import( '@/views/vms/device/veriHost'),
      meta: {title: '验证仪管理', icon: ''}
    },
    {
      path: '/vms/device/veriPoint',
      name: 'veriPoint',
      component: () => import( '@/views/vms/device/veriPoint'),
      meta: {title: '验证测点管理', icon: ''}
    },
    {
      path: '/vms/device/certificate',
      name: 'certificate',
      component: () => import( '@/views/vms/device/certificate'),
      meta: {title: '校准证书管理', icon: ''}
    },
    {
      path: '/vms/device/model',
      name: 'model',
      component: () => import( '@/views/vms/device/model'),
      meta: {title: '验证测点管理', icon: ''}
    },
    {
      path: '/vms/distriTemplate',
      name: 'distriTemplate',
      component: () => import('@/views/vms/distriTemplate'),
      meta: {title: '布点模板', icon: ''}
    },
    {
      path: '/vms/verifierTemplate',
      name: 'verifierTemplate',
      component: () => import('@/views/vms/verifierTemplate'),
      meta: {title: '验证人员模板', icon: ''}
    }
    ]
  },
  {
    path: '/approve',
    component: Layout,
    redirect: '/approve/join',
    name: 'approve',
    meta: {title: '验证对象管理', icon: 'el-icon-s-custom'},
    children: [{
      path: '/approve/join',
      name: 'join',
      component: () => import('@/views/approve/join'),
      meta: {title: '我参与的', icon: ''}
    },
    {
      path: '/approve/submit',
      name: 'submit',
      component: () => import('@/views/approve/submit'),
      meta: {title: '我提交的', icon: ''},
    },
    ]
  },
  {
    path: '/tms',
    component: Layout,
    redirect: '/tms/report',
    name: 'tms',
    meta: {title: '模板管理', icon: 'el-icon-s-custom'},
    children: [{
      path: '/tms/report',
      name: 'report',
      component: () => import('@/views/tms/report'),
      meta: {title: '报告模板', icon: ''}
    },
      {
        path: '/tms/plan',
        name: 'plan',
        component: () => import('@/views/tms/plan'),
        meta: {title: '方案模板', icon: ''},
      },
    ]
  },
  {
    path: '/sys',
    component: Layout,
    redirect: '/sys/user',
    name: 'sys',
    meta: {title: '系统管理', icon: 'el-icon-s-custom'},
    children: [{
      path: '/sys/user',
      name: 'user',
      component: () => import('@/views/sys/user'),
      meta: {title: '用户管理', icon: '',permissions: ['sys:user:edit'],}
    },
    {
      path: '/sys/role',
      name: 'role',
      component: () => import('@/views/sys/role'),
      meta: {title: '角色管理', icon: '',permissions: ['sys:role:edit'],},
    },
    {
      path: '/sys/menu',
      name: 'menu',
      component: () => import('@/views/sys/menu'),
      meta: {title: '菜单管理', icon: '',permissions: ['sys:menu:edit']},
    },
    {
      path: '/sys/dept',
      name: 'dept',
      component: () => import('@/views/sys/dept'),
      meta: {title: '部门管理', icon: '',permissions: ['sys:dept:edit']},
    },
    {
      path: '/sys/post',
      name: 'post',
      component: () => import('@/views/sys/post'),
      meta: {title: '岗位管理', icon: '',permissions: ['sys:post:edit']},
    },
    {
      path: '/sys/dict',
      name: 'dict',
      component: () => import('@/views/sys/dict'),
      meta: {title: '字典管理', icon: '',permissions: ['sys:dict:edit']},
    },
    {
      path: '/sys/message',
      name: 'message',
      component: () => import('@/views/sys/message'),
      meta: {title: '通知公告', icon: '',permissions: ['sys:message:edit']},
    },
    {
      path: '/sys/log',
      name: 'log',
      component: () => import('@/views/sys/log'),
      meta: {title: '日志管理', icon: '',permissions: ['sys:log:edit']},
    },
    {
      path: '/sys/personalCenter',
      name: 'personalCenter',
      component: () => import('@/views/sys/personalCenter'),
      meta: {title: '个人中心', icon: '',permissions: ['sys:log:edit']},
    },
    ]
  },
  {
    path: '/tenant',
    component: Layout,
    redirect: '/tenant/list',
    name: 'tenant',
    meta: {title: '租户管理', icon: 'el-icon-s-custom'},
    children: [{
      path: '/tenant/list',
      name: 'list',
      component: () => import('@/views/tenant/list'),
      meta: {title: '租户管理', icon: '',permissions: ['sys:tenant:edit']},
    },
    {
      path: '/tenant/package',
      name: 'package',
      component: () => import('@/views/tenant/package'),
      meta: {title: '租户套餐管理', icon: ''},
    },
    {
      path: '/tenant/userRequest',
      name: 'userRequest',
      component: () => import('@/views/tenant/userRequest'),
      meta: {title: '用户申请管理', icon: ''},
    },
    ]
  },
  {path: '/docCenter', name: 'docCenter',meta: {title: '文档中心', icon: 'el-icon-s-custom'}, component: () => import( '../views/docCenter/index.vue')},


  {path: '/helpCenter/updateRecord', name: 'updateRecord', component: () => import( '../views/helpCenter/updateRecord.vue')},
  {path: '/helpCenter/runBook', name: 'runBook', component: () => import( '../views/helpCenter/runBook.vue')},
  {path: '/helpCenter/issue', name: 'issue', component: () => import( '../views/helpCenter/issue.vue')},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to,from,next)=>{

  const token = localStorage.getItem("authorization-token")
  // 1、如果用户访问登录页面， 但是token已经存在， 跳转到首页
  if(to.path==="/login" && token){
    next("/home");
    return
  }

  // 2、如果用户访问不是登录页面，但是没有token，跳转到登录页面
  if(to.path!=="/login" && !token){
    next("/login")
    return;
  }
  //
  if (token &&  store.state.userMenuData.menuData.length == 0){
    // 获取用户的菜单数据
    let getUserRoutersApiRes = await getUserRoutersApi();
    if(!getUserRoutersApiRes)return;
    const userPermissions = getUserRoutersApiRes.data.permissions || [];
    let newUserMenuData = [{title: "首页", path:"/home", icon: "el-icon-s-home",}]
    let ret = getUserRoutersApiRes.data.map(item=>{
      if(item.children){
          return {
            title: (item && item.meta && item.meta.title) || '',
            icon:  (item && item.meta && item.meta.icon) || '',
            path: item.path,
            children: item.children.map(sitem=>{
              if (sitem.children){
                return{
                  title: (sitem && sitem.meta && sitem.meta.title) || '',
                  icon:  (sitem && sitem.meta && sitem.meta.icon) || '',
                  path:item.path+"/"+sitem.path,
                  children: sitem.children.map( vitem =>{
                    if(!vitem.meta.permissions || checkPermissions(vitem.meta.permissions, userPermissions)) {
                      return{
                        title:(sitem && sitem.meta && vitem.meta.title) || '',
                        path:item.path+"/"+sitem.path+'/'+ vitem.path,
                      }
                    }
                  })
                }
              }else {
                if(!sitem.meta.permissions || checkPermissions(sitem.meta.permissions, userPermissions)) {
                  return{
                    title: (sitem && sitem.meta && sitem.meta.title) || '',
                    icon:  (sitem && sitem.meta && sitem.meta.icon) || '',
                    path:item.path+"/"+sitem.path
                  }
                }
              }
            })
          }
      }else{
        if(!item.meta.permissions || checkPermissions(item.meta.permissions, userPermissions)) {
          return {
            title:  (item && item.meta && item.meta.title) || '',
            icon:  (item && item.meta && item.meta.icon) || '',
            path: item.path,
          }
        }
      }

    })
    newUserMenuData = [...newUserMenuData,...ret];
    //console.log(newUserMenuData);
    store.commit("userMenuData/changeMenuData",newUserMenuData)


    //生成user可以访问的路由数组
    // let newChildrenRoutes=[{path:"/home", meta:{titles:["首页"]}, component: () => import('../views/home/index.vue')}]
    // getUserRoutersApiRes.data.forEach( item =>{
    //   if(item.children){
    //     let ret = item.children.map(sitem=>{
    //       if(sitem.children){
    //         return {
    //           path:item.path+"/"+sitem.path,
    //           component: () => import(`../views${item.path}/${sitem.path}.vue`),
    //           meta:{titles:[ (item && item.meta && item.meta.title) || '',sitem.meta.title]},
    //           children: sitem.children.map( vitem =>{
    //             return{
    //               path:sitem.path+"/"+ vitem.path,
    //               component: () => import(`../views${sitem.path}/${vitem.path}.vue`),
    //               meta:{titles:[ (sitem && sitem.meta && sitem.meta.title)||'',vitem.meta.title]},
    //             }
    //           })
    //
    //         }
    //       }else {
    //         return {
    //           path:item.path+"/"+sitem.path,
    //           component: () => import(`../views${item.path}/${sitem.path}.vue`),
    //           meta:{titles:[ (item && item.meta && item.meta.title) || '',sitem.meta.title]},
    //
    //         }
    //       }
    //     })
    //      newChildrenRoutes = [...newChildrenRoutes,...ret]
    //   }else {
    //     return {
    //       path:item.path,
    //       component: () => import(`../views/${item.path}.vue`),
    //       meta:{
    //         titles:[ (item && item.meta && item.meta.title) || '']
    //       }
    //     }
    //   }
    // });
    // newChildrenRoutes = [...newChildrenRoutes,...ret]

    // 要把这个生成好的数组添加到layout路由里面的children，做为子路由
    // newChildrenRoutes.forEach(item=>{
    //   router.addRoute("layout",item);
    // });
    // next(to.path); //加了to.path 重新走一遍路由守卫,检查用户能否访问该路径,防止刷新页面后页面丢失或空白页
    // return
  }

  if (Array.isArray(to.meta.permissions)) {
    const userPremissions = store.getters.userPremissions;
    if (!checkPermissions(userPremissions, to.meta.permissions)) {
      next({ path: '*' })
    }
  }

  next()

})

//在router文件中写入，修改报错信息
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

export default router
